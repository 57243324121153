@import 'colors';
@import 'mixin';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: var(--background);
  color: var(--text-color);
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  vertical-align: top;
  font-weight: 300;
  color: var(--text-color);
  border: 0;
  outline: none;
}
textarea {
  resize: none;
  line-height: 20px;
}

a,
button {
  @extend .trstn;
  &:hover {
    @extend .trstn;
  }
}

::selection {
  background-color: $blue;
  color: $white;
}

::-moz-selection {
  background-color: $blue;
  color: $white;
}

button {
  cursor: pointer;
  background: transparent;
}

:disabled {
  cursor: not-allowed;
  @include opacity(0.5);
}

input,
textarea,
select {
  width: 100%;
  background: var(--input);
  padding: 5px;
  color: var(--text-color);
  @extend .bsbb;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--text-color);
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--text-color);
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--text-color);
  opacity: 0.5;
}

textarea {
  min-height: 250px;
  font-family: 'Source Code Pro', monospace;
}

button {
  padding: 0;
}
