@import '../../styles/colors';
@import '../../styles/mixin';

.UlArea {
  @extend .display-flex;
  @extend .flex-direction-row;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.LiRows {
  @extend .display-flex;
  @extend .flex-direction-column;
  padding: 5px;
  @extend .bsbb;
  line-height: 20px;
  font-family: 'Source Code Pro', monospace;
  span {
    text-align: right;
  }
}

.LiArea {
  width: 100%;
  textarea {
    border-left: 1px solid var(--border);
    font-weight: 600;
    overflow: hidden;
    min-width: 100%;
    min-height: 100%;
  }
}
