@import '../../../../styles/colors';
@import '../../../../styles/mixin';

.UlList {
  @extend .display-flex;
  @extend .flex-direction-column;
  li {
    @extend .display-flex;
    @extend .flex-direction-row;
    @extend .flex-justify-between;
    border-left: 5px solid var(--background);
    border-bottom: 1px solid var(--border);
  }
}

.Link {
  padding: 10px 10px 10px 5px;
  border-left: 5px solid var(--background);
  text-decoration: none;
  @extend .bsbb;
  width: 100%;
  @extend .display-flex;
  @extend .flex-direction-column;
  word-break: break-all;
  span {
    font-size: 8px;
    margin-bottom: 5px;
  }
  height: 80px;
}

.Remove {
  padding: 10px;
  color: $my-red;
  &:hover {
    color: $my-red-hover;
  }
}

.Active {
  border-left: 5px solid $my-green !important;
}

.Empty {
  color: rgba($my-red, 0.5);
  margin-top: 10px;
}
