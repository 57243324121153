@import '../../../styles/colors';
@import '../../../styles/mixin';

.LiList {
  width: 200px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 1px solid var(--border);
}

.LiWriter {
  @include calc('width', '100% - 200px');
  position: fixed;
  top: 0;
  left: 200px;
  height: 100%;
}
