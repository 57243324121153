@import '../../../../styles/colors';
@import '../../../../styles/mixin';

.UlMenu {
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .bsbb;
  padding: 10px;
  border-bottom: 1px solid var(--border);
  li {
    &:first-of-type {
      width: 100%;
    }
    &:last-of-type {
      @extend .display-flex;
      @extend .flex-center;
      margin-left: 10px;
    }
  }
}

.BtnNew {
  @include border-radius(2px);
  padding: 5px 10px;
  font-size: 12px;
  background: $my-green;
  width: 100%;
  // border: 1px solid $my-green;
  color: $white;

  &:hover {
    background: $my-green-hover;
  }
}
