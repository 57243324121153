@import 'colors';
@import 'mixin';

:root {
  --background: #{$white};
  --text-color: #{$grey-900};
  --input: #{$white};
  --border: #{$grey-300};
}

[data-theme='dark'] {
  --background: #{$grey-900};
  --text-color: #{$white};
  --input: #{$grey-800};
  --border: #{$grey-800};
}
